import axios from "axios";

axios.defaults.headers.common["Content-Type"] = "application/json";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    config.baseURL = `https://api.rbs.kouelab.com`;
    // config.baseURL = `http://localhost:3351`;

    const token = localStorage.getItem("jwt");
    config.headers.common["authorization"] = "Bearer " + token;

    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
// axios.interceptors.response.use(
//   (response) => {
//     if (response.data?.message)
//       notify(response.data.message, "Ok", "theme", "fa fa-check");
//     return response;
//   },
//   (error) => {
//     if (error.response) {
//       if (error.response.data?.message)
//         notify(error.response.data.message, "Erreur", "danger");
//       if (error.response.status === 401) {
//         store.commit("auth/SET_CURRENT_USER", null);
//       }
//     }
//     return Promise.reject(error);
//   }
// );

export default axios;
